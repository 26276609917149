import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { ContextLayout } from "../../utility/context/Layout";
import { PageRelease } from "../../components";
import terms from "../../utils/terms.json";
import {
  storeUser,
  storeBranch,
} from "../../redux/actions/authentication/authActions";
import { connect } from "react-redux";

const ProtectedRoute = ({ component: Component, layout, path }) => {
  const dispatch = useDispatch();
  let user =
    useSelector((state) => state.auth.user) ||
    JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    dispatch(storeUser(user));
  }, [dispatch, user]);

  // TODO: Fazer check de qual  veio do Autenticador e fazer o match.
  const selectedBranch = useSelector((state) => state.auth.selectedBranch) || {
    codigo: 1,
    codigoConta: 1,
    nome: "Filial Inlog",
    cnpj: "00.000.000/0001-00",
    status: "Ativo",
    tempoSegundosEnvioLocalizacaoEquipe: 60,
    tempoSegundosValidadeLocalizacaoEquipe: 300,
    timeZone: -3,
    chaveGoogleMaps: null,
    tipoVinculacaoDemanda: "AutomaticaParaEquipeComMenosDemandas",
    margemTolerDistEquipeLocalDemandaEmMt: null,
    matriz: false,
    armazenamentoContratadoEmMb: 1024,
    armazenamentoUtilizadoEmMb: 0,
    prefeiturasVisibilidade: [],
  };

  useEffect(() => {
    dispatch(storeBranch(selectedBranch));
  }, [dispatch, selectedBranch]);

  const inlogToken = Cookies.get(
    `Inlog-token-$${process.env.REACT_APP_FISCOL_NODE_ENV ?? ""}`
  );
  const permissions = user?.acoesSistema?.[path.replace("/", "").split("/")[0]];
  const propertyValues = permissions
    ? Object.values(permissions).filter(Boolean)
    : null;
  const permissionData = propertyValues
    ? {
        capabilities: permissions,
        showScreen: propertyValues.length > 0,
        menuIsAvailable: propertyValues.length > 0,
      }
    : {};

  if (!user && !inlogToken) {
    window.location.href = process.env.REACT_APP_FISCOL_AUTHENTICATOR_URL;
    return null;
  }

  if (!user && inlogToken) {
    return <Redirect to="/login" />;
  }

  if (
    user &&
    user.versaoTermosUso !== terms.version &&
    !user?.tokenUsuarioImpersonate &&
    !user?.usuarioInlog
  ) {
    return <Redirect to="/terms" />;
  }

  if (user && path !== "/" && !permissionData.showScreen) {
    return <Redirect to="/not-allowed" />;
  }

  if (
    user &&
    user.tokenUsuarioImpersonate &&
    !user.token &&
    path !== "/impersonate"
  ) {
    return <Redirect to="/impersonate" />;
  }

  return (
    <PageRelease
      route={path}
      user={user}
      renderNoAccess={() => <Redirect to="/not-allowed" />}
    >
      <Route
        path={path}
        render={(props) => (
          <ContextLayout.Consumer>
            {(context) => {
              const LayoutTag =
                context[layout + "Layout"] || context.VerticalLayout;
              return (
                <LayoutTag {...props} permission={permissionData}>
                  <Component
                    {...props}
                    pageMatch={props.computedMatch}
                    permissions={permissionData}
                  />
                </LayoutTag>
              );
            }}
          </ContextLayout.Consumer>
        )}
      />
    </PageRelease>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth,
  };
};

export default connect(mapStateToProps, {})(ProtectedRoute);
